import { render, staticRenderFns } from "./DealerList.vue?vue&type=template&id=551c42d8&scoped=true&"
import script from "./DealerList.vue?vue&type=script&lang=js&"
export * from "./DealerList.vue?vue&type=script&lang=js&"
import style0 from "./DealerList.vue?vue&type=style&index=0&id=551c42d8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "551c42d8",
  null
  
)

export default component.exports