export const columns = [
  {
    title: '大区名称',
    dataIndex: 'areaName',
    key: 'areaName',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },

  {
    title: '事务所名称',
    dataIndex: 'firmName',
    key: 'firmName',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '经销商',
    dataIndex: 'agentName',
    key: 'agentName',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '联系人',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
  {
    title: '联系电话',
    dataIndex: 'agentPhone',
    key: 'agentPhone',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },

  {
    title: '添加时间',
    dataIndex: 'addTime',
    key: 'addTime',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },

  {
    title: '累计购买金额',
    dataIndex: 'accumulatedAmount',
    key: 'accumulatedAmount',
    /*width:150,*/
    align: 'center',
    ellipsis: true
  },
]
