<template>
  <div>
    <a-modal title="选择商品" v-model="visible" width="70%" :footer="null" :maskClosable="false">
      <div class="realtor">
        <a-form-model ref="searchData" :label-col="{xs:7}" :wrapper-col="{xs:17}">
          <a-row :gutter="8">
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="经销商名称:">
                <a-input v-model="searchData.dealerName" placeholder="请输入经销商名称"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
                <a-button type="primary" @click="getList(0)" icon="search">查询</a-button>
                <a-button type="default" @click="getList(1)" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <a-table :data-source="tableData" :loading="tableLoading" :rowKey="record => record.id" :pagination="page"
                 bordered @change="changeTable"
                 :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
          <a-table-column title="大区" data-index="areaName" align="center"></a-table-column>
          <a-table-column title="事务所" data-index="firmName" align="center"></a-table-column>
          <a-table-column title="经销商" data-index="agentName" align="center"></a-table-column>
          <a-table-column title="联系人" data-index="name" align="center"></a-table-column>
          <a-table-column title="手机号" data-index="agentPhone" align="center"></a-table-column>
        </a-table>
      </div>
      <div class="footer-btns">
        <a-button @click="onCancel">取消</a-button>
        <a-button type="primary" :loading="btnsLoading" @click="onSubmit">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {

  data() {
    return {
      visible: false,
      searchData: {},
      tableLoading: false,
      tableData: [],
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0
      },
      selectedRowKeys: [],
      selectedRows: [],
      btnsLoading: false,
    }
  },
  methods: {
    // 打开弹框触发
    isShow() {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.visible = true
      this.$emit('reload')
      this.getList()
    },

    changeTable(pagination) {
      this.page = pagination
      this.page.pageNumber = pagination.current
      this.getList()
    },
    getList(num) {
      if(num == 1){
        this.searchData = {}
        this.page.pageNumber = 1
      }
      const data = Object.assign({}, {
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize,
        ...this.searchData
      })
      this.axios.get('/api/dealer/dealer/dealerInfo/auth/listAll', {params: data}).then(res => {
        if (res.code === 200) {
          let vo = res.body.records.map(e => {
            return {
              agentId: e.id,
              agentName: e.dealerName,
              name: e.person,
              agentPhone: e.phone,
              areaName: e.areaName,
              firmName: e.firmName
            }
          })
          this.tableData = vo;
        }
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },

    // 关闭弹框
    onCancel() {
      this.visible = false
    },
    onSubmit() {
      this.axios.post('/api/dealer/customer/dealerPurchaseQualification/add', this.selectedRows).then(res => {
          this.visible = false
          this.$emit('reload')
      })
    },
  }
}
</script>

<style scoped>
.footer-btns {
  text-align: right;
  border-top: 1px solid #e8e8e8;
  padding-top: 20px;
  margin-top: 20px;
}
</style>